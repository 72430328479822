<template>
  <a-config-provider :locale="locale">
  <div id="app">
  <router-view>

  </router-view>

  </div>
  </a-config-provider>
</template>

<script>

  import zhCn from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  name: 'App',
  components: {

  },
  data(){
    return{
      // 2.赋值
      locale:zhCn
    }
  }
}
</script>

<style>

</style>
