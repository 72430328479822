
 const deepCopy = (obj) => {


     let data1=JSON.parse(JSON.stringify(obj))

    return data1;
}
export default {
    deepCopy
}