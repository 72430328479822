import Vue from 'vue'
import App from './App.vue'

import axios from '@/axios'
import router from '@/router'
import store from '@/store'   //导入store
import Antd from 'ant-design-vue';
import { message } from 'ant-design-vue'
import utils from '@/utils'

import 'ant-design-vue/dist/antd.css';
import moment from 'moment'
import LocaleProvider from 'ant-design-vue'
import {export2Excel } from '@/excel/excelFu'
Vue.prototype.$excel=export2Excel;
Vue.use(LocaleProvider)
Vue.prototype.$moment = moment
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$message=message
Vue.prototype.$utils=utils
Vue.use(Antd);



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
// //路由守卫
