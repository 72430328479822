import Vue from 'vue'
import Vuex from 'vuex'
/*import { getItem, setItem } from '@/untils/storage.js'

 import decodeJWT from 'jwt-decode'*/

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user:null
    },
    mutations: {
        setUser(state,user){
            state.user=user;
            sessionStorage.setItem('user',JSON.stringify(user));


        }
    },
    getters:{
        User:(state)=>{
            if (state.user)
            return state.user;
            else
                if(sessionStorage.getItem('user')){
                    return JSON.parse(sessionStorage.getItem('user'));
                }else
                {
                    return null;
                }

        }
    },
    actions: {
    },
    modules: {

    }
})

