import Vue from 'vue'
import VueRouter from 'vue-router'
/*import { getItem, setItem } from "@/utils/storage"

import store from '@/store'*/
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
 const routes = [
    { //
        path: '/',
        name: '/',
        component: () => import('@/views/login'),   
        redirect: '',
    },
            { //
                path: '/home',
                name:'home',
                component: () => import('@/views/home'),
                redirect: '',
                children:[
                    {
                        path: '/bsource',
                        name:'bsource',
                        component: () => import('@/views/source/sindex.vue'),
                        redirect: '',
                        meta:{
                            Authorization:true
                        }

                    },
                    {
                        path: '/sourceclass',
                        name:'sourceclass',
                        component: () => import('@/views/sourceclass'),
                        redirect: '',
                        meta:{
                            Authorization:true
                        }

                    },
                    {
                        path: '/member',
                        name:'member',
                        component: () => import('@/views/member'),
                        redirect: '',
                        meta:{
                            Authorization:true
                        }

                    },
                    {
                        path: '/membershare',
                        name:'membershare',
                        component: () => import('@/views/member/share'),
                        redirect: '',
                        meta:{
                            Authorization:true
                        }

                    },
                    {
                        path: '/card',
                        name:'card',
                        component: () => import('@/views/card'),
                        redirect: '',
                        meta:{
                            Authorization:true
                        }

                    },
                    {
                        path: '/company',
                        name:'company',
                        component: () => import('@/views/company'),
                        redirect: '',
                        meta:{
                            Authorization:true
                        }

                    },
                    {
                        path: '/cardhistory',
                        name:'cardhistory',
                        component: () => import('@/views/card/history.vue'),
                        redirect: '',
                        meta:{
                            Authorization:true
                        }

                    }

                    ]
            }




]
const router = new VueRouter({
    base: '/dist/',
    routes
})


router.beforeEach((to, from, next) => {

    next();


})

export default router