
import { getItem } from '@/utils/storage'
import axios from 'axios'
import router from '@/router'

// axios.defaults.headers.common['Authorization'] = "123456";
// 添加请求拦截器

console.log(process.env);

if (process.env.NODE_ENV === "production") {//判断环境变量，如果是生产环境设置生效
    axios.defaults.baseURL = process.env.VUE_APP_API;
}

axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    // const token = window.localStorage.getItem("Token");
    // token && (config.headers.Authorization = token);
    //console.log("在发送请求之前做些什么")
    if (getItem("Token") && getItem("UserToken")) {
        // console.log(`token值：${getItem("Token")}`)
        config.headers.Authorization = getItem("Token")
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

axios.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if (response.status === 200) {
            if (response.data.Message === "FAIL" && response.data.Data === "not_login") {
                router.push("/login")
            }
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是2开头的的情况
    // 这里可以跟你们的后台开发人员协商好统一的错误状态码
    // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
    // 下面列举几个常见的操作，其他需求可自行扩展
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                // 401: 未登录
                // 未登录则跳转登录页面，并携带当前页面的路径
                // 在登录成功后返回当前页面，这一步需要在登录页操作。
                case 401:
                    this.$message.success('身份验证失败，请关闭重新进入。');
                    break;

                // 403 token过期
                // 登录过期对用户进行提示
                // 清除本地token和清空vuex中token对象
                // 跳转登录页面
                case 403:
                    this.$message.success('登录过期，请关闭重新进入。');
                    // 清除token
                    break;

                // 404请求不存在
                case 404:
                    //Toast.fail("您访问的网页不存在。");
                    break;
                // 其他错误，直接抛出错误提示
                default:
                    this.$message.success('error.response.data.message');
            }
            return Promise.reject(error.response);
        }
    }
);

export default axios
