export function export2Excel(columns, list, headerTitle) {
    require.ensure([], () => {
        const { export_json_to_excel } = require('./Export2Excel'); // 这个引用路径根据文件位置进行调整
        let tHeader = []
        let filterVal = []
        if (!columns) {
            return;
        }
        columns.forEach(item => {
            tHeader.push(item.title)
            filterVal.push(item.key)
        })
        const data = list.map(v => filterVal.map(j => v[j]))

        export_json_to_excel(tHeader, data, headerTitle,true);
    })
}

